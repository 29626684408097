import React, { useState, useEffect } from "react";
import style from "./VrtSession.module.sass";
import SelectButtonRelax from "./SelectButtonRelax";
import SelectButtonEducational from "./SelectButtonEducational";
import ToggleButton from "./ToggleButton";

function VrtSessionEditForm({ editProps }) {
  const {
    session,
    sessionParameters,
    presetRelax,
    sceneryRelax,
    sceneryEducational,
    titleEducationalLevel,
    presetEducational,
    titlePreset,
    updateUrl,
  } = editProps;

  // Stato per gestire i campi
  const [selectedId, setSelectedId] = useState(sessionParameters?.preset_id || null);
  const [selectValues, setSelectValues] = useState(sessionParameters || {});
  const [toggleStatuses, setToggleStatuses] = useState({
    caa: sessionParameters?.caa || false,
    dist: sessionParameters?.dist || false,
  });

  

  // Gestione submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      selected_preset_id: selectedId,
      selected_params_values: selectValues,
      ...toggleStatuses,
    };

    try {
      const response = await fetch(updateUrl, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessions: formData }),
      });

      const data = await response.json();

      if (response.ok) {
        window.location.href = data.redirectUrl;
      } else {
        console.error("Error updating session:", data.errors);
      }
    } catch (error) {
      console.error("Error updating session:", error);
    }
  };

  // Cambiamenti per i toggle
  const handleToggle = (name, newStatus) => {
    setToggleStatuses((prevStatuses) => ({
      ...prevStatuses,
      [name]: newStatus,
    }));
  };

  // Cambiamenti nei valori selezionati
  const handleSelectValuesChange = (newValues) => {
    setSelectValues(newValues);
  };

  // Determina il componente di preset
  const choosePresets = () => {
    if (session.vrt_typology_session_id === 1) {
      // Relax
      return (
        <SelectButtonRelax
          presetRelax={presetRelax}
          scenery={sceneryRelax}
          titlePreset={titlePreset}
          sessionParameters={sessionParameters}
          onSelect={setSelectedId}
          onSelectValuesChange={handleSelectValuesChange}
        />
      );
    } else if (session.vrt_typology_session_id === 2) {
      // Educational
      return (
        <SelectButtonEducational
          levelName={titleEducationalLevel}
          scenery={sceneryEducational}
          presetEducational={presetEducational}
          sessionParameters={sessionParameters}
          onSelect={setSelectedId}
          onSelectValuesChange={handleSelectValuesChange}
        />
      );
    }
    return null;
  };

  return (
    <div className={style.VrtSessionEdit}>
      <h2>Modifica Parametri Sessione</h2>
      <form onSubmit={handleSubmit}>
        {/* Preset Selection */}
        {choosePresets()}

        {/* Opzioni aggiuntive solo per Educational */}
        {session.vrt_typology_session_id === 2 && (
          <div>

            <ToggleButton
              initialStatus={toggleStatuses.dist}
              onToggle={(newStatus) => handleToggle("dist", newStatus)}
              title="Distrattori o Obiettivi"
              name="dist"
            />
          </div>
        )}

        {/* Submit */}
        <div className={style.formField}>
          <button type="submit" className={style.buttonSubmit}>
            Salva Modifiche
          </button>
        </div>
      </form>
    </div>
  );
}

export default VrtSessionEditForm;
